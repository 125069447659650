@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	margin: 0;
	padding: 40px;
	margin-top: 24px;
}
