@import "Configs/Theme/constants.scss";

.root {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;


    @media (max-width: 500px) {
        top:10vh;
        height: 90vh;
    }
}