@import "Configs/Theme/constants.scss";

.root {
    display: flex;
    gap: 12px;

    .retry-icon {
        width: 20px;
        height: 20px;
    }
}
