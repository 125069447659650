@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;

	// Hover and Active states are specified in the theme

	.left {
		display: flex;
		align-items: center;

		img {
			width: 40px;
			height: 40px;
			margin-right: 16px;
			border-radius: 8px;

			object-fit: cover;

			&[data-is-user="true"] {
				border-radius: 50%;
			}
		}
	}

	.title-container {
		display: flex;
		flex-direction: column;

		:first-child {
			color: var(--color-neutral-900);
		}

		:last-child {
			color: var(--color-neutral-500);
		}
	}

	.right {
		display: flex;
		align-items: center;
	}
}
