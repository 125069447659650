@import "Configs/Theme/constants.scss";

.not-connected-menu {
	z-index: 5;
	top: 72px;
	right: 0;
	width: 100%;
	position: fixed;
	background: var(--color-generic-background);
	box-shadow: var(--shadow-small);
	transition: transform 300ms $custom-easing, opacity 300ms $custom-easing, width 300ms $custom-easing;
	transform: translateX(0%);
	opacity: 1;

	.content {
		display: flex;
		flex-direction: column;
		position: relative;
		gap: 24px;
		padding: 16px;
		overflow: auto;
		height: 100%;
		margin: auto;

		.connect {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}

		.nav-section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 8px;

			@media screen and (max-width: $screen-xs) {
				grid-template-columns: 1fr;
			}
		}
	}

	&.closed {
		pointer-events: none;
		transform: translateX(100%);
		opacity: 0;

		.shadow {
			opacity: 0;
		}
	}
}
