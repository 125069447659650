@import "Configs/Theme/constants.scss";

.root {
	.info {
		margin-bottom: 24px;
		color: var(--color-neutral-500);
	}
}

.buttons-container {
	display: flex;
	flex-direction: row-reverse;
	gap: 8px;

	@media (max-width: $screen-m) {
		flex-direction: column;
	}
}
