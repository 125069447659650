@import "Configs/Theme/constants.scss";

.root {
	border: var(--border);
	border-radius: var(--main-searchbar-border-radius);
	padding-left: 15px;
	padding-right: 10px;
	background-color: var(--main-searchbar-background);

	display: flex;
	align-items: center;
	flex: 1;
	height: 40px;
	min-height: 40px;

	.input-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		input {
			margin-left: 11px;
			border: none;
			background: none;
			width: 100%;

			font-family: var(--font-family-secondary);
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: var(--color-neutral-900);

			&:focus-visible {
				outline: none;
			}

			&::placeholder {
				font-family: var(--font-family-secondary);
				color: var(--color-neutral-400);
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				text-overflow: ellipsis;
			}
		}
	}

	svg {
		cursor: pointer;
		width: 14px;
		height: 14px;
		min-width: 14px;
		min-height: 14px;

		path {
			stroke: var(--color-neutral-500);
		}
	}
}
