@import "Configs/Theme/constants.scss";

.root {

	.modal {
		width: 440px;

		@media screen and (max-width: $screen-s) {
			width: max(60vw, 280px);
		}

	}

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 24px;
		padding-top: 16px;

		.social-logins-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;
		}

		.or-container {
			width: 100%;
			.line {
				width: 177.5px;
				height: 1px;
				background: var(--line-solid, #374151);
			}
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			gap: 16px;
		}

		.external-wallets-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;
		}
	}
}
