.root {
	display: flex;
	flex-direction: column;
	align-items: center;

	.qrcode {
		margin-bottom: 24px;
		height: 298px;
		width: 298px;
	}
}
