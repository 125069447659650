@import "Configs/Theme/constants.scss";

.root {
	margin: 24px 0;
	padding: 24px 0;
	border-top: 1px solid var(--color-neutral-300);
	border-bottom: 1px solid var(--color-neutral-300);

	& > div:not(:last-child) {
		margin-bottom: 8px;
	}

	.container {
		padding: 0 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		margin-bottom: 16px;
	}

	.username {
		padding: 4px 8px;
		border: 1px solid var(--color-neutral-300);
		border-radius: 32px;
		color: var(--color-neutral-600);
	}
}
