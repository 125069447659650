@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	z-index: 5;

	justify-content: center;
	align-items: center;

	position: sticky;
	top: 0;

	background-color: var(--header-background);
	backdrop-filter: blur(15px);
	box-shadow: var(--shadow-small);

	.content {
		z-index: 5;
		flex-grow: 1;
		width: 100%;
		max-width: 1440px;
		display: flex;

		padding: 16px 64px;

		@media (max-width: $screen-m) {
			padding: 16px 32px;
		}

		@media (max-width: $screen-s) {
			padding: 16px;
		}

		&.hide-shadow {
			transition: box-shadow 300ms $custom-easing;
			box-shadow: none;
		}

		.segments {
			flex: 1;
			display: flex;
			flex-direction: row;

			.segment {
				flex: 1;
				display: flex;
				justify-content: space-between;
				gap: 24px;
				align-items: center;
			}

			.segment-left {
				padding-right: 24px;
				flex-grow: 0;
			}

			.segment-right {
				flex-grow: 1;
				justify-content: right;

				@media screen and (max-width: $screen-s) {
					padding-left: 0;
				}

				.language-mode-switcher {
					&.connected {
						@media screen and (max-width: $screen-s) {
							display: none;
						}
					}
				}

				.searchbar {
					flex: 1;
				}
			}

			.burger {
				width: fit-content;
				display: none;
				padding-left: 12px;
				justify-content: flex-end;

				@media (max-width: $screen-s) {
					display: flex;
				}
			}

			.profileButton {
				@media (max-width: $screen-s) {
					display: none;
				}
			}

			.connectButton {
				@media (max-width: $screen-s) {
					display: none;
				}
			}
		}
	}

	@media (max-width: $screen-m) {
		transition: transform 500ms $custom-easing;

		&[data-open="opened"] {
			transform: translateY(0);
		}
	}
}

[top-menu-status="closed"] {
	@media (max-width: $screen-m) {
		.root {
			&[data-open="closed"] {
				transform: translateY(-72px);
			}
		}
	}
}

[header-search-bar-extended="true"] {
	@media (max-width: $screen-s) {
		.root .segments {
			.segment-left {
				display: none;
			}

			.segment-right {
				> :not(.searchbar) {
					display: none;
				}
			}

			.burger {
				display: none;
			}
		}
	}
}

@media (max-width: $screen-s) {
	.root .segments .segment-left a:not(.logo) {
		display: none;
	}
}
