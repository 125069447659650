@import "Configs/Theme/constants.scss";

.root {
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    border-radius: var(--transaction-card-radius, 8px);
    border: 1px solid var(--transaction-card-border, rgba(0, 0, 0, 0.00));
    background: var(--transaction-card-background, rgba(107, 114, 128, 0.10));

    .duration-container, .amount-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;

        .label {
            flex: 1 0 0;
            display: flex;
            align-items: flex-start;
            align-self: stretch;
        }
    }
}
