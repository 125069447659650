.root {
	display: block;
	border: 1px solid var(--color-neutral-300);
	background-color: var(--user-button-background);
	border-radius: 32px;
	padding: 2px 8px;
	height: 24px;
	width: fit-content;
	white-space: nowrap;

	cursor: pointer;
	user-select: none;

	p {
		color: var(--color-neutral-600);
	}

	&:hover {
		background: var(--user-button-background-hover);
	}

	&:active {
		background: var(--user-button-background-active);
	}
}