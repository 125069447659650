@import "Configs/Theme/constants.scss";

.root {
	.type-selector {
		position: relative;
		display: flex;
		text-align: center;
		user-select: none;
		margin-bottom: 32px;

		> :not(:last-child) {
			margin-right: 24px;
		}

		.label {
			cursor: pointer;
			margin-bottom: 8px;

			&:hover p {
				color: var(--tab-switcher-color-active);
			}

			&[data-selected="true"] p {
				color: var(--tab-switcher-color-active);
			}
		}

		.selected-underline {
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translateX(0);
			width: 1px;

			&[data-animated-once="true"] {
				transition: transform 150ms $custom-easing, width 150ms $custom-easing;
			}

			height: 4px;
			border-radius: 5px;
			background: var(--tab-switcher-color-active);
		}
	}
}