.root {
	display: grid;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}

.buttonRow {
	padding-top: 8px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	button:first-child {
		margin-right: 8px;
	}
	button {
		flex: 1;
	}
}

.no-token {
	width: 100%;
	padding: 24px;
	background-color: var(--color-neutral-50);
	display: flex;
	flex-direction: column;
	gap: 8px;

	p {
		text-align: center;
	}
}
