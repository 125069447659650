.root {
	position: relative;

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.loader {
			width: 24px;
			height: 24px;
		}
	}

	.neutral {
		background: var(--alert-neutral-background, rgba(156, 163, 175, 0.1));
	}
}
