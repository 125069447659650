@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	position: relative;

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: -1;
	}

	.container {
		display: flex;
		position: absolute;
		z-index: 5;
		flex-direction: column;
		border: var(--border);
		border-radius: var(--border-radius-language-mode-switcher);
		background: var(--select-filter-background);
		box-shadow: var(--shadow-small);
		max-width: 160px;
		transition: all 350ms $custom-easing;
		opacity: 1;
		overflow: hidden;
		left: 50%;
		transform: translateX(-50%);
		width: 160px;
		gap: 12px;
		padding: 12px;
		top: 48px;

		.title {
			text-align: center;
		}

		&.container-closed {
			opacity: 0;
			visibility: hidden;
		}

		.container-ul {
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			.container-li {
				cursor: pointer;
				text-align: center;
				list-style: none;
				padding: 8px 12px;
				width: 100%;
				border-radius: var(--button-border-radius);
				transition: background-color 350ms $custom-easing;

				&:hover {
					background: var(--color-neutral-100);
				}
			}
		}
	}
}