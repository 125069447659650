.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.input-container {
		display: flex;
		gap: 8px;

		.input {
			width: 100%;
			height: 40px;
			border: 1px solid var(--color-neutral-300);
			border-radius: 5px;
		}
	}
}
