@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background-color: var(--color-neutral-100);
	}

	&:active {
		background-color: var(--color-neutral-200);
	}

	svg {
		width: 24px;
		height: 24px;
	}

	&.fill {
		svg {
			path {
				fill: var(--color-neutral-500);
			}
		}

		&:hover {
			svg {
				path {
					fill: var(--color-neutral-700);
				}
			}
		}

		&:active {
			svg {
				path {
					fill: var(--color-neutral-900);
				}
			}
		}
	}

	&.stroke {
		svg {
			path {
				stroke: var(--color-neutral-500);
			}
		}

		&:hover {
			svg {
				path {
					stroke: var(--color-neutral-700);
				}
			}
		}

		&:active {
			svg {
				path {
					stroke: var(--color-neutral-900);
				}
			}
		}
	}
}
