@import "Configs/Theme/constants.scss";

.buttons-container {
	display: flex;
	flex-direction: row-reverse;
	gap: 8px;

	@media (max-width: $screen-m) {
		flex-direction: column;
	}
}
