@import "Configs/Theme/constants.scss";

.root {
	margin-top: 12px;
	width: 100%;

	.container {
		padding: 0 12px;
		display: flex;
		align-items: center;
		height: 48px;
		border-radius: 8px;
		cursor: pointer;

		&:hover {
			background-color: var(--color-neutral-100);

			.icon-container {
				.icon {
					path {
						stroke: var(--color-neutral-900);
					}
				}
			}

			.text {
				color: var(--color-neutral-900);
			}
		}

		.icon-container {
			margin-right: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			border-radius: 8px;
			background-color: var(--color-neutral-200);
		}

		.text {
			color: var(--color-neutral-500);
		}
	}
}
