.loader {
	width: 32px;
	height: 32px;
}

.icon {
	width: 24px;
	height: 24px;
	margin-right: 19px;
}

.icon_success {
	width: 32px;
	height: 32px;
	color: var(--color-primary-500);
}

.error {
	color: var(--color-error-500);
}

.error-button {
	padding-top: 24px;
	width: 95%;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 13px;

	svg {
		width: 26px;
		stroke: var(--color-success-500);
	}
}
