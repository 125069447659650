body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-family-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 0;
	overflow-x: hidden;
	max-width: 100vw;
	background-color: var(--color-generic-background);

	#root {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	#w3a-modal {
		top: 80px;
		height: 100vh;
		z-index: 2;

		align-items: flex-start;
	}

	.w3a-modal__inner--active {
		max-height: 80%;
	}

	&[overlay-open="true"] {
		overflow-y: hidden;
	}

	.pac-container {
		border: var(--border);
		border-radius: var(--main-searchbar-border-radius);
		background: var(--select-filter-background);
		box-shadow: var(--shadow-small);
		padding: 8px;
		font-family: var(--font-family-secondary);
	}

	.pac-container:after {
		background-image: none !important;
		height: 0;
	}

	.pac-icon {
		display: none;
	}

	.pac-item {
		display: flex;
		flex-direction: column;
		padding: 8px 12px;
		align-items: flex-start;
		justify-content: flex-start;
		background: var(--select-filter-item-background);
		border-radius: 8px;
		cursor: pointer;
		background: var(--select-filter-item-background);
		border: 0;
		color: var(--color-neutral-900);
	}

	.pac-item:hover {
		background: var(--color-neutral-100);
	}

	.pac-item-query {
		color: var(--color-neutral-900);
	}
}

a {
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: var(--color-neutral-900) !important;
	caret-color: var(--color-neutral-900);
	-webkit-background-clip: text !important;
}
