.root {
	z-index: 7;
	.adressButtonContainer {
		> :first-child {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	.top {
		display: flex;
		flex-direction: column;
		text-align: left;
		gap: 8px;
	}

	.labelWalletInfo {
		margin-top: 20px;
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;
	}

	.bottomButtons {
		padding-top: 24px;
		margin: auto auto;

		button {
			width: 100%;
		}

		> :not(:last-child) {
			margin-bottom: 8px;
		}
	}
}
