@import "Configs/Theme/constants.scss";

.root {
	position: fixed;
	z-index: 6;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(4px);
	}

	.container {
		position: relative;
		width: 632px;
		height: fit-content;
		padding: 2px;
		margin: 0 8px;

		border: var(--modal-border);
		border-radius: var(--modal-border-radius);
		background: var(--modal-background-color);
		box-shadow: var(--shadow-small);

		.cross {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;

			@media (max-width: 375px) {
				right: 5px;
				top: 0;
			}
		}

		.sub-container {
			padding: 14px;
			max-height: 90vh;
			overflow-y: auto;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.wert {
		min-width: 0;

		.sub-container {
			@media (max-width: $screen-xs) {
				padding: 10px;
			}

			@media (max-width: 360px) {
				padding: 0;
			}
		}
	}

	.transparant-background {
		background-color: transparent;
		box-shadow: none;
	}

	&[data-side-background="true"] {
		.container {
			max-width: 711px;

			.cross {
				@media (max-width: $screen-s) {
					right: 32px;
					top: 100px;
				}
			}

			.sub-container {
				padding: 0;
				display: flex;
				width: 350px;

				p {
					max-width: 711px;
				}

				@media (max-width: $screen-s) {
					display: block;
				}

				.banner {
					@media (max-width: $screen-s) {
						overflow: hidden;
					}
				}
			}
		}

		.side-image {
			height: 100%;

			@media (max-width: $screen-s) {
				display: none;
			}
		}

		.top-image {
			@media (min-width: $screen-s) {
				display: none;
			}

			@media (max-width: $screen-s) {
				width: 100%;
				max-height: 82px;
				min-height: 82px;
			}
		}
	}
}