.root {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: fit-content;
	height: fit-content;
	border-radius: 50%;

	img {
		border-radius: 50%;
		object-fit: cover;
	}

	&.small {
		img {
			width: 32px;
			height: 32px;
		}
	}

	&.medium {
		img {
			width: 40px;
			height: 40px;
		}
	}

	&.large {
		img {
			width: 56px;
			height: 56px;
		}
	}

	&:active {
		background-color: var(--color-neutral-200);
	}

	&:hover {
		background-color: var(--color-neutral-100);
	}
}
