@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	flex: 1;

	.content {
		flex: 1;
		padding-bottom: var(--content-padding-bottom);
		width: 100%;

		margin-right: auto;
		margin-left: auto;

		&[data-padding-x-disabled="false"] {
			padding-right: 64px;
			padding-left: 64px;
	
			@media (max-width: $screen-m) {
				padding-right: 32px;
				padding-left: 32px;
			}
	
			@media (max-width: $screen-s) {
				padding-right: 16px;
				padding-left: 16px;
			}
		}

		&[data-padding-top-disabled="false"] {
			padding-top: 32px;
		}

		&[data-padding-y-disabled="false"] {
			padding-right: 64px;
			padding-left: 64px;
	
			@media (max-width: $screen-m) {
				padding-right: 32px;
				padding-left: 32px;
			}
	
			@media (max-width: $screen-s) {
				padding-right: 16px;
				padding-left: 16px;
			}
		}
		
	}
}
