@import "Configs/Theme/constants.scss";

.root {
	z-index: 3;
	@media (min-width: $screen-m) {
		display: none;
	}

	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;

	.nav {
		opacity: 1;
		overflow: auto;
		position: relative;
		background: var(--color-generic-background);
		z-index: 3;
		box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.08);
		font-size: 16px;
		max-height: 100vh;
		transition: transform 400ms $custom-easing, opacity 350ms $custom-easing;
		transform: translateY(0);
	}

	.shadow {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 2;
		position: absolute;
		opacity: 1;
		transition: opacity 300ms $custom-easing;
	}

	&[status="closed"] {
		pointer-events: none;
		.nav {
			transition: transform 400ms $custom-easing, opacity 250ms $custom-easing;
			transform: translateY(-100%);
			opacity: 0;
		}

		.shadow {
			opacity: 0;
		}
	}

	.top-menu-nav {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		gap: 40px;
		padding: 0 0 32px 0;
		margin-top: 72px; // 72px = header
		background-color: var(--color-generic-black-transparency);
		backdrop-filter: blur(15px);
	}

	.top-menu-nav a {
		text-decoration: none;
		color: var(--color-text);
	}

	.top-menu-burger {
		position: absolute;
		top: 32px;
		right: 32px;
	}
}
