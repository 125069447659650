.root {
	background-color: transparent;
	border: none;
	border-radius: 50%;
	transition: background-color 0.1s ease-out;
	cursor: pointer;

	&.small {
		width: 24px;
		height: 24px;
		padding: 2px;
	}

	&.medium {
		width: 40px;
		height: 40px;
		padding: 8px;
	}

	&.large {
		width: 48px;
		height: 48px;
		padding: 8px;
	}

	&.light {
		path {
			stroke: var(--color-neutral-500);
		}

		&:hover {
			background-color: var(--color-neutral-100);

			path {
				stroke: var(--color-neutral-700);
			}
		}

		&:active {
			background-color: var(--color-neutral-200);

			path {
				stroke: var(--color-neutral-900);
			}
		}

		&:disabled {
			background-color: transparent;
			cursor: not-allowed;

			path {
				stroke: var(--color-neutral-300);
			}
		}
	}

	&.filled {
		background-color: var(--color-neutral-50);

		path {
			stroke: var(--color-neutral-500);
		}

		&:hover {
			background-color: var(--color-neutral-100);

			path {
				stroke: var(--color-neutral-700);
			}
		}

		&:active {
			background-color: var(--color-neutral-200);

			path {
				stroke: var(--color-neutral-900);
			}
		}

		&:disabled {
			background-color: var(--color-neutral-50);
			cursor: not-allowed;

			path {
				stroke: var(--color-neutral-300);
			}
		}
	}

	&.translucide {
		background-color: rgba(17, 24, 39, 0.3);

		path {
			stroke: var(--color-neutral-300);
		}

		&:hover {
			background-color: rgba(17, 24, 39, 0.5);

			path {
				stroke: var(--color-neutral-50);
			}
		}

		&:active {
			background-color: rgba(17, 24, 39, 0.6);

			path {
				stroke: var(--color-neutral-50);
			}
		}

		&:disabled {
			background-color: rgba(17, 24, 39, 0.1);
			cursor: not-allowed;

			path {
				stroke: var(--color-neutral-50);
			}
		}
	}
}
