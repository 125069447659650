.root {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	border-radius: 50%;
	width: fit-content;
	height: fit-content;

	svg {
		path {
			stroke: var(--color-neutral-500);
		}
	}

	&.small {
		width: 24px;
		height: 24px;
		svg {
			width: 14px;
			height: 14px;
		}
	}

	&.medium {
		width: 40px;
		height: 40px;
		svg {
			width: 17.35px;
			height: 17.35px;
		}
	}

	&.large {
		width: 40px;
		height: 40px;
		svg {
			width: 24px;
			height: 24px;
		}
	}

	&:hover {
		background-color: var(--color-neutral-100);
		svg {
			path {
				stroke: var(--color-neutral-700);
			}
		}
	}
	&:active {
		background-color: var(--color-neutral-200);
		svg {
			path {
				stroke: var(--color-neutral-900);
			}
		}
	}

	&[data-active="true"] {
		background-color: var(--color-neutral-200);
		svg {
			path {
				stroke: var(--color-neutral-900);
			}
		}
	}

	&[data-fill="true"] {
		svg {
			path {
				stroke: initial;
				fill: var(--color-neutral-500);
			}
		}

		&:hover {
			background-color: var(--color-neutral-100);
			svg {
				path {
					stroke: initial;
					fill: var(--color-neutral-700);
				}
			}
		}

		&:active {
			background-color: var(--color-neutral-200);
			svg {
				path {
					stroke: initial;
					fill: var(--color-neutral-900);
				}
			}
		}

		&[data-active="true"] {
			background-color: var(--color-neutral-200);
			svg {
				path {
					stroke: initial;
					fill: var(--color-neutral-100);
				}
			}
		}
	}
}
