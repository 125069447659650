.route {
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	text-decoration: none;
	white-space: nowrap;

	.content {
		display: flex;
		align-items: center;

		.link {
			color: var(--header-link-color);
		}

		svg {
			margin-right: 11px;

			path {
				stroke: var(--header-link-color);
			}
		}
	}

	&:hover {
		.link {
			color: var(--header-link-color-hover);
		}

		svg path {
			stroke: var(--header-link-color-hover);
		}
	}

	&[data-variant="menu-item"] {
		border-radius: 8px;

		.content {
			padding: 12px;
		}

		&:hover {
			background: var(--user-menu-link-background-hover);
		}

		&:active {
			background: var(--color-neutral-200);
		}
	}

	&.active-route {
		.link {
			color: var(--header-link-color-hover);
		}

		svg path {
			stroke: var(--header-link-color-hover);
		}
	}
}
