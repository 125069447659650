@import "Configs/Theme/constants.scss";

.root {
	> :not(:last-child) {
		margin-bottom: 24px;
	}

	.current_bid {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 16px 12px 16px 12px;
		background-color: var(--color-neutral-100);
		border-radius: 8px;

		.value {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			.currency {
				display: inline-flex;
				align-items: center;
				> :first-child {
					margin-right: 6px;
				}
			}
		}
	}

	.color {
		p {
			color: var(--color-neutral-900) !important;
		}
	}
}

.buttons-container {
	.approved-payment {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		color: var(--color-success-500);

		.icon {
			width: 20px;
			height: 20px;
		}
	}

	button {
		width: 100%;
		margin-top: 8px;
	}
}
