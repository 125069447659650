.root {
	display: flex;
	align-items: center;
	gap: 8px;

	.icon {
		margin-top: 3px;
		min-width: 20px;
		min-height: 20px;
		max-width: 20px;
		max-height: 20px;
		color: var(--color-primary-500);
	}

	.button {
		min-width: unset;
	}
}
