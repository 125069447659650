@import "Configs/Theme/constants.scss";

.root {
	z-index: 4;
	height: calc(100vh - 72px); // 72px = header
	top: 72px;
	right: 0;
	width: 390px;
	position: fixed;
	background: var(--user-menu-background);
	box-shadow: var(--shadow-small);

	@media screen and (max-width: $screen-xs) {
		width: 100vw;
		box-shadow: none;
	}

	transition: transform 300ms $custom-easing, opacity 300ms $custom-easing, width 300ms $custom-easing;
	transform: translateX(0%);
	opacity: 1;

	.shadow {
		top: 0px;
		left: calc(390px - 100vw);
		right: 0;
		bottom: 0;
		z-index: 2;
		position: fixed;
		opacity: 1;
		transition: opacity 300ms $custom-easing;
		width: calc(100vw - 390px);
	}

	.content {
		padding: 16px;
		display: flex;
		flex-direction: column;
		overflow: auto;
		height: 100%;

		.container {
			display: flex;
			flex-direction: column;
			position: relative;
			gap: 32px;
		}
	}

	&.closed {
		pointer-events: none;
		transform: translateX(100%);
		opacity: 0;

		.shadow {
			opacity: 0;
		}
	}

	.nav-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 8px;
	}

	.buttons-section {
		display: flex;
		flex-direction: column;
		margin-bottom: 38px;

		> :not(:last-child) {
			margin-bottom: 8px;
		}
	}

	.logout {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.settings {
		display: flex;
		align-items: center;
		border-radius: 8px;
		cursor: pointer;
		padding: 12px;

		svg {
			margin-right: 11px;

			path {
				stroke: var(--color-neutral-500);
			}
		}

		&:hover {
			background: var(--color-neutral-100);

			p {
				color: var(--color-neutral-900);
			}

			svg path {
				stroke: var(--color-neutral-900);
			}
		}
	}
}

@media (max-width: $screen-s) {
	.root .segments .segment-left a:not(.logo) {
		display: none;
	}
}
