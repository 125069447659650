@import "Configs/Theme/constants.scss";

.buttons-container {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	margin-top: 8px;

	button {
		flex: 1;
	}

	@media (max-width: $screen-s) {
		flex-direction: column-reverse;
		gap: 8px;
	}
}
