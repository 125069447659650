@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: flex-end;

	width: fit-content;
	height: fit-content;

	.token-price {
		display: flex;
		align-items: center;
		white-space: nowrap;

		> :not(:last-child) {
			margin-right: 10px;
			@media screen and (max-width: $screen-s) {
				margin-right: 5px;
			}
		}
	}

	img {
		width: 16px;
		height: 16px;
	}

	> :not(:last-child) {
		margin-right: 8px;
	}

	&[data-conversion-direction="column"] {
		flex-direction: column;

		> :not(:last-child) {
			margin-right: 0;
		}
	}
}
