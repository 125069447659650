.summary {
	> :first-child {
		margin-bottom: 16px;
	}
	.summary-rows {
		display: flex;
		flex-direction: column;
		> :not(:first-child) {
			margin-top: 8px;
		}

		.summary-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			> :last-child {
				color: var(--color-neutral-900);
			}

			.row {
				display: flex;

				> :last-child {
					margin-left: 8px;
				}
			}
		}
	}
}

.border {
	border-top: 1px solid var(--color-neutral-200);
	padding-top: 24px;
	margin-top: 24px;
}
