.loader {
	width: 32px;
	height: 32px;
}

.icon {
	width: 24px;
	height: 24px;
	margin-right: 19px;
}

.error {
	color: var(--color-error-500);
	stroke: var(--color-error-500);
}

.button {
	padding-top: 14px;
	width: 95%;
}
