@import "Configs/Theme/constants.scss";

.root {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 600px;

    .button-container {
        display: flex;
        padding: 16px;
        align-items: center;
        align-content: space-between;
        gap: 8px;

        .close-button {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            border-radius: 8px;
        }

        .custom-button {
            display: flex;
            flex: 1 0 0;
            border-radius: 8px;
        }
    }
}
