.root {
    position:fixed;
    top:0;
    left:0;
	background-color: white;
	height: 100vh;
	width: 100vw;
    z-index:20;
	.cross {
		z-index:20;
		position: absolute;
		top: 15px;
		right: 15px;
	}
}
