@import "Configs/Theme/constants.scss";

.root {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 85%;

	h5 {
		color: var(--color-neutral-900);
	}
}
