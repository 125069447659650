@import "Configs/Theme/constants.scss";

.root {
	position: fixed;
	z-index: 4;
	top: 72px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	padding: 4px 64px;
	width: 100vw;
	background-color: var(--registration-banner-background);
	animation: translateIn 0.3s ease-in-out;
	transition: top 500ms cubic-bezier(0.645, 0.045, 0.355, 1);

	&.header-closed {
		top: 0;
	}

	@media screen and (max-width: $screen-s) {
		padding: 4px 24px;
	}

	@media screen and (max-width: $screen-xs) {
		padding: 4px 12px;
	}

	.icon,
	.close-icon {
		min-width: 20px;
		min-height: 20px;
		max-width: 20px;
		max-height: 20px;
	}

	.icon {
		margin-top: 3px;
		color: var(--color-primary-500);
	}

	.close-icon {
		cursor: pointer;
	}
}

@keyframes translateIn {
	0% {
		transform: translateY(-44px);
	}
	100% {
		transform: translateY(0);
	}
}
