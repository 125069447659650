.root {
    path {
        stroke: var(--color-neutral-500);
    }

    .bottom-buttons {
        path {
            stroke: var(--color-neutral-900);
        }

        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;

    }
}
