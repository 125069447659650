@import "Configs/Theme/constants.scss";

.root {
	animation: spin 2s linear infinite;
	width: 100%;
	height: 100%;
	opacity: 1;

	path:first-child {
		fill: var(--loader-big-part-background);
		//stroke: white;
	}

	path:last-child {
		fill: var(--loader-small-part-background);
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}
