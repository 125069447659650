.root {
	&[data-isreadonly="true"] {
		opacity: 0.5;
		pointer-events: none;
	}

	.content {
		flex: 1;
		cursor: text;
		display: flex;
		flex-direction: column;
		position: relative;

		.input-container {
			display: flex;
			align-items: center;
			border: var(--border);
			width: 100%;
			height: 40px;
			overflow: hidden;
			border-radius: var(--border-radius-input);

			&[data-large="true"] {
				border-radius: var(--border-radius-textarea);
				height: 100%;
			}

			svg {
				padding-left: 12px;
				width: 30px;
			}

			input {
				border: none;
				width: 100%;
				padding-left: 8px;
			}
		}

		> :not(:last-child) {
			margin-bottom: 8px;
		}

		.label {
			color: var(--color-neutral-900);
		}
	}
}