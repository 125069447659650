.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 8px;
	margin-bottom: 8px;;
	border-radius: 8px;
	border: 0.5px solid var(--color-neutral-400);

	cursor: pointer;
	user-select: none;

	.left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin: 12px;

		p {
			color: var(--color-neutral-900);
		}

		img {
			width: 25.6px;
			height: 25.6px;
			margin-right: 11px;
		}
	}

	&:hover {
		background: var(--color-neutral-100);
	}

	&:active {
		background: var(--color-neutral-200);
	}
}
