.root {
	border-top: 1px solid var(--color-neutral-200);
	padding-top: 24px;
	margin-top: 24px;

	.potential-earnings-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.padding {
		padding: 8px 0px;
	}
}
