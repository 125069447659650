@import "Configs/Theme/constants.scss";

.root {
	> :not(:last-child) {
		margin-bottom: 24px;
	}

	.row {
		display: flex;
		flex-direction: row;

		justify-content: space-between;
		align-items: center;

		.left {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 16px;
			.nftImage {
				position: relative;
				background-size: cover;
				border-radius: 8px;
				width: 64px;
				height: 64px;
			}
		}

		.right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			.currency {
				display: inline-flex;
				align-items: center;
				> :first-child {
					margin-right: 6px;
				}
			}
		}
	}

	.color {
		p {
			color: var(--color-neutral-900) !important;
		}
	}

	.condition-policies {
		display: flex;
		justify-content: left;
		align-items: center;
		gap: 16px;
		line-height: 0 !important;
		font-size: 0% !important;

		.text-condition {
			display: flex;
			flex-direction: row;
			gap: 3px;
		}
	}
}
