@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	align-self: stretch;
	margin-bottom: 24px;

	.name-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;

		.logo-container {
			height: 32px;
			width: 32px;
			margin-right: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.logo {
			margin: auto;
		}
		.name {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}
}
