.root {
	max-width: 100%;

	&[data-isreadonly="true"] {
		opacity: 0.5;
		pointer-events: none;
	}

	.content {
		flex: 1;
		cursor: text;
		display: flex;
		flex-direction: column;
		position: relative;

		.amount-input {
			display: flex;
			max-width: 100%;
		}

		> :not(:last-child) {
			margin-bottom: 8px;
		}
	}

	.tipRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 8px;
	}
}
