.root {
	display: flex;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 8px;

	.input-field {
		width: 100%;
	}

	path {
		stroke: var(--color-primary-500);
	}

	.paste-button {
		margin-top: 32px;
	}
}
