@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	border-radius: var(--button-border-radius);
	background: var(--color-neutral-100);
	margin-top: 8px;
	border: var(--border-button);

	.button {
		border-radius: var(--button-border-radius);
		border: 0;
		cursor: pointer;
		background: transparent;
		font-family: var(--font-family-secondary);
		font-weight: var(--font-weight-medium);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		user-select: none;
		transition: background-color 150ms ease-out;
		white-space: nowrap;
		color: var(--color-neutral-900);
		background-color: transparent;
		width: 100%;
		height: 40px;
		font-size: 16px;
		line-height: 17px;
		padding: 8px 12px;

		&:hover {
			background-color: var(--color-neutral-200);
		}

		&.selected {
			background-color: var(--selector-bar-selected-background-color);
			color: var(--selector-bar-selected-color);
		}

		&:disabled {
			opacity: 0.1;
			pointer-events: none;
		}

		.start-icon {
			display: flex;
			margin-right: 5px;

			svg {
				min-height: 16px;
				max-height: 16px;
				min-width: 16px;
				max-width: 16px;
			}
		}
	}
}
