@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 8px;

	.icon {
		min-width: 24px;
		min-height: 24px;
		margin-right: 12px;
	}

	.text {
		line-height: 20px;
	}

	&.default {
		background-color: var(--alert-message-default-background);
		border: var(--alert-message-default-border);

		.icon {
			path {
				stroke: var(--alert-message-default-color);
			}
		}

		.text {
			color: var(--alert-message-default-color);
		}
	}

	&.important {
		background-color: var(--alert-message-important-background);
		border: var(--alert-message-important-border);

		.icon {
			path {
				stroke: var(--alert-message-important-color);
			}
		}

		.text {
			color: var(--alert-message-important-color);
		}
	}

	&.warning {
		background-color: var(--alert-message-warning-background);
		border: var(--alert-message-warning-border);

		.icon {
			path {
				stroke: var(--alert-message-warning-color);
			}
		}

		.text {
			color: var(--alert-message-warning-color);
		}
	}

	&.error {
		background-color: var(--alert-message-error-background);
		border: var(--alert-message-error-border);

		.icon {
			path {
				stroke: var(--alert-message-error-color);
			}
		}

		.text {
			color: var(--alert-message-error-color);
		}
	}
}