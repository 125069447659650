.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.left {
		display: flex;
		flex-direction: row;
		align-items: center;
		> :not(:first-child) {
			margin-left: 16px;
		}
		.nftImage {
			position: relative;
			background-size: cover;
			border-radius: 8px;
			width: 64px;
			height: 64px;
		}
	}
}

.color {
	p {
		color: var(--color-neutral-900);
	}
}
