@import "Configs/Theme/constants.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 32px;
    height: 100vh;
    width: 100vw;

    .text {
        .title {
            margin-bottom: 24px;
        }
    }
}