@import "Configs/Theme/constants.scss";

.root {
	position: relative;

	@media (min-width: $screen-xs) {
		min-width: 130px;
		max-width: 100%;
	}

	@media (min-width: $screen-s) {
		min-width: 160px;
		max-width: 100%;
	}

	@media (max-width: $screen-xs) {
		flex: 1;
	}

	&.small {
		min-width: 120px;
		max-width: 100%;
	}

	.container-label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border: var(--border);
		border-radius: var(--main-searchbar-border-radius);
		background: var(--select-filter-background);
		cursor: pointer;
		padding: 8px 16px;
		z-index: 2;
		height: 40px;
		white-space: nowrap;

		&:hover {
			background: var(--color-neutral-50);
		}

		&[data-open="true"] {
			background: var(--color-neutral-100);
		}

		&[data-border-right-collapsed="true"] {
			border-radius: var(--border-radius-select);
		}

		.container-input {
			display: flex;
			align-items: center;

			span {
				display: flex;

				.icon {
					display: flex;
					margin-right: 8px;
					align-items: center;
				}
			}
		}

		.chevron-icon {
			height: 20px;
			color: var(--color-neutral-900);
			transition: all 350ms $custom-easing;

			&[data-open="true"] {
				transform: scaleY(-1);
			}
		}
	}

	.container-ul {
		z-index: 3;
		list-style: none;
		margin: 0;
		padding: 8px;
		position: absolute;
		outline: 0;
		display: flex;
		flex-direction: column;
		border: var(--border);
		border-radius: var(--main-searchbar-border-radius);
		background: var(--select-filter-background);
		box-shadow: var(--shadow-small);
		width: 100%;
		transition: all 350ms $custom-easing;
		opacity: 1;
		overflow: hidden;
		top: 50px;

		&[data-open="false"] {
			height: 0;
			opacity: 0;
			border: none;
			visibility: hidden;
		}
	}

	.container-li {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 8px 12px;
		border-radius: 8px;
		cursor: pointer;
		background: var(--select-filter-item-background);

		&:hover {
			background: var(--color-neutral-100);
		}

		&:active {
			background: var(--color-neutral-200);
		}

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.token-icon {
		max-width: 20px;
		display: flex;
		align-items: center;
		margin-right: 11px;

		> svg {
			height: 20px;
			margin-right: 11px;
		}

		> img {
			height: 20px;
			width: 20px;
		}
	}

	&.disabled {
		.container-label {
			cursor: auto;

			.container-input {
				display: flex;
				align-items: center;

				span {
					color: var(--color-neutral-300);
				}
			}

			.chevron-icon {
				color: var(--color-neutral-300);
			}
		}

		.container-li {
			cursor: auto;
		}
	}
}
