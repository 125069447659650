@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 10;
	right: 0;
	top: 0;
	padding: 0px;
	width: 0px;
	max-height: calc(100vh - 80px);
	overflow-y: auto;
	overflow-x: hidden;

	&.open {
		width: 443px;
		top: 70px;
		padding: 16px;

		@media (max-width: $screen-xs) {
			width: unset;
		}
	}

	@media (min-width: $screen-s) {
		&.user-menu-open {
			right: 390px;
		}
	}

	& > *:not(:first-child) {
		margin-top: 16px;
	}

	@media (max-width: 444px) {
		width: unset;
		left: 0;
		padding: 8px;
	}
}
