.root {
	.success-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		gap: 32px;
		.success-wrapper {
			display: flex;
			padding: var(--spacing-lg, 24px) 0px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: var(--spacing-sm, 8px);
			align-self: stretch;
			border-radius: var(--radius-rounded, 16px);
			background: var(--color-neutral-300);

			.success-icon {
				width: 48px;
				height: 48px;
				svg {
					color: #247141;
				}
			}
		}
	}
}
