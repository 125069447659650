@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	border: var(--border);
	border-radius: var(--main-searchbar-border-radius);
	width: 100%;
	height: 40px;

	.icon {
		position: relative;
		bottom: 14px;
		width: 24px;
		height: 20px;
		margin-right: 8px;
		cursor: pointer;
	}

	&[data-border-right-collapsed="true"] {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}

	&[data-border-left-collapsed="true"] {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}

	input {
		input:-webkit-autofill {
			-webkit-text-fill-color: var(--color-neutral-400) !important;
		}

		border: 0;
	}
}