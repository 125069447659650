@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.profile {
		display: flex;
		align-items: center;
		width: 100%;

		.username-address {
			display: flex;
			flex-direction: column;
			margin-left: 12px;

			> :not(:last-child) {
				margin-bottom: 8px;
			}

			.username {
				display: flex;
				gap: 8px;
				align-items: center;

				.check-badge {
					min-width: 20px;
					min-height: 20px;
					max-width: 20px;
					max-height: 20px;
					margin-top: 3px;

					color: var(--color-primary-500);
				}
			}
		}

		.language-mode-switcher {
			width: 100%;
			justify-content: flex-end;

			@media screen and (min-width: $screen-s) {
				display: none;
			}
		}
	}

	.cross {
		@media (max-width: $screen-s) {
			display: none;
		}
	}

	svg {
		cursor: pointer;
	}
}
