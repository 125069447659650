@import "Configs/Theme/constants.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 24px 0;
    gap: 24px;

    .image-container {
        width: 160px;
        height: 160px;

        .image {
            border-radius: 8px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .message-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .message-status {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 24px;
            height: 24px;
        }

        .success {
            color: var(--color-success-500);
        }

        .failure {
            color: var(--color-error-500);
        }
    }
}
