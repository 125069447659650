@import "Configs/Theme/constants.scss";

.root {
	cursor: pointer;

	.checkbox {

		.unchecked-icon {
			stroke: var(--color-neutral-500);
			fill: var(--color-neutral-50);
			&:hover {
				stroke: var(--color-neutral-900);
			}
		}

		.checked-icon {
			fill: var(--color-primary-500);
		}

		input {
			display: none;
		}
	}
}
