@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-input-field-material);
	border-radius: var(--border-radius-amount-input-field);
	padding: 0 12px;
	border: var(--border);
	height: 40px;
	p {
		color: var(--color-neutral-700);
	}
}
