@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 24px 32px;
	margin-top: 64px;
	background-color: var(--footer-background);

	@media (max-width: $screen-m) {
		padding-right: 32px;
		padding-left: 32px;
	}

	@media (max-width: $screen-s) {
		padding-right: 16px;
		padding-left: 16px;
	}

	.content {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;

		> :not(:last-child) {
			margin-right: 100px;
		}

		@media (max-width: $screen-s) {
			flex-wrap: wrap;
			gap: 24px;
			margin-bottom: 0;
		}

		.left {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.logo-container {
				display: flex;
				gap: 16px;

				.additional-logo {
					height: 40px;
					object-fit: contain;
					width: fit-content;
				}
			}

			.nav-container {
				display: flex;
				align-items: center;
				gap: 24px;
				flex-wrap: wrap;
			}
		}

		.right {
			display: flex;
			flex-direction: column;
			max-width: 520px;
			margin-bottom: 24px;

			> :not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	.down-part {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 32px;

		@media (max-width: $screen-m) {
			flex-direction: column-reverse;
			align-items: normal;

			> :not(:last-child) {
				margin-top: 20px;
			}
		}

		.social-medias {
			display: flex;
			align-items: center;
			gap: 16px;

			@media (max-width: $screen-s) {
				margin-top: 24px;
			}
		}

		.policies {
			display: flex;
			white-space: nowrap;
			gap: 16px;

			@media (max-width: $screen-s) {
				flex-wrap: wrap;
			}

			p {
				color: var(--footer-link-color);
			}

			.footer-link {
				color: var(--footer-link-color);
			}

			a {
				text-decoration: none;

				&:hover {
					p {
						color: var(--color-neutral-900);
					}
				}
			}
		}
	}
}
