@import "Configs/Theme/constants.scss";

.links {
	width: max-content;
	color: var(--header-link-color);

	&:hover {
		color: var(--header-link-color-hover);
	}

}