.root {
	.title {
		/* Desktop/H2 */

		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 34px;
		/* identical to box height, or 121% */

		color: #000000;
	}

	.input-container {
		margin-top: 24px;
		display: grid;
		gap: 24px;
	}
}

.button-container {
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	> :not(:last-child) {
		margin-bottom: 8px;
	}
}
