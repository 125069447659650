.root {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed !important;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 20;
	backdrop-filter: blur(6.5px);

	.content {
		display: flex;
		width: 100%;
		height: 100%;
		max-width: 350px;
		max-height: 600px;
		z-index: 21;
		border: var(--border);
		box-shadow: var(--shadow-small);
	}

	.cross {
		z-index: 21;
		position: absolute;
		top: 15px;
		right: 15px;
	}
}