.root {
	display: grid;
	gap: 16px;

	.confirmInfo {
		display: grid;
		grid-template-columns: 15% 90%;

		.column {
			display: grid;
			grid-template-rows: repeat(4, 50px);
		}

		.titles {
			grid-column: 1;
		}

		.data {
			grid-column: 2;
		}
	}

	.total {
		display: grid;
		grid-template-columns: 15% 90%;

		border-top: 1px solid;
		border-bottom: 1px solid;
		border-color: var(--color-neutral-200);
		padding: 16px 0px 16px 0px;
	}
	.buttonRow {
		padding-top: 8px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 8px;

		button:first-child {
			margin-right: 8px;
		}
		button {
			flex: 1;
		}
	}
}
