.root {
	border: 1px solid var(--color-neutral-300);
	background-color: var(--color-generic_white);
	border-radius: 32px;
	padding: 2px 8px;
	width: fit-content;

	&:hover {
		background: var(--color-neutral-50);
	}

	&:active {
		background: var(--color-neutral-100);
	}

	.content {
		cursor: pointer;
		user-select: none;

		p {
			margin-right: 8px;
			color: var(--color-neutral-600);
		}

		&[data-size="large"] {
			padding: 8px 16px;
		}

		.icon {
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				path {
					stroke: var(--color-neutral-500);
				}
			}

			.check-icon {
				path {
					stroke: var(--color-success-700);
				}
			}
		}
	}
}
