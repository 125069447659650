@import "Configs/Theme/constants.scss";

@keyframes loadbar-animation {
	from {
		transform: scaleX(1);
	}

	to {
		transform: scaleX(0);
	}
}

@keyframes slide-left {
	from {
		opacity: 0;
		transform: translateX(120%);
	}

	to {
		opacity: 1;
		transform: translate(0%);
	}
}

@keyframes slide-right {
	from {
		opacity: 1;
		transform: translateX(0);
	}

	to {
		opacity: 0;
		transform: translate(120%);
	}
}

.root {
	border: 1px solid black;
	pointer-events: all;
	position: relative;
	padding: 16px;
	background: var(--toast-background-color);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
	border-radius: var(--toast-border-radius);
	animation-name: slide-left;
	animation-duration: 400ms;
	animation-timing-function: $custom-easing;
	animation-fill-mode: forwards;

	&[data-will-close="true"] {
		animation-duration: 200ms;
		animation-name: slide-right;
		animation-timing-function: $custom-easing;
		animation-fill-mode: forwards;
	}

	.loadbar {
		position: absolute;
		top: 0;
		right: var(--toast-border-radius);
		left: 0;
		height: 2px;
		background: var(--toast-loader-background);
		border-radius: 5px;
		transform-origin: right;
		transform: scaleX(0);
		animation: loadbar-animation var(--data-duration) linear;
	}

	.header {
		font-family: var(--font-family-primary);
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.cross {
			cursor: pointer;
		}
	}

	.text-icon_row {
		display: flex;
		gap: 16px;
		align-items: center;
		min-height: 40px;

		@media (max-width: $screen-xs) {
			gap: 19px;
		}

		.icon {
			width: 32px;
			height: 32px;
		}

		.text-container {
			width: 300px;

			@media (max-width: $screen-xs) {
				max-width: 229px;
				white-space: unset;
			}

			.title {
				color: var(--toast-text-color);

				a {
					display: flex;
					line-height: 24px;
					max-height: 24px;

					align-items: center;

					svg {
						padding: 1px;
						width: 20px;
						height: 20px;
						margin: 2px 10px;

						path {
							stroke: var(--color-success-500);
						}
					}
				}
			}
		}
	}

	.button {
		margin-top: 16px;
	}
}