@import "Configs/Theme/constants.scss";

.root {
	max-height: 256px;
	overflow-y: auto;

	.items {
		> :not(:last-child) {
			margin-bottom: 8px;
		}
	}
}
