.root {
	background: var(--color-neutral-200);
	width: 38px;
	height: 20px;
	border-radius: 64px;
	transition: all 250ms ease-in-out;
	display: flex;
	align-items: center;
	cursor: pointer;

	.point {
		margin: 2px;
		width: 16px;
		height: 16px;
		border-radius: 30px;
		background: var(--color-generic-white);
		transition: all 200ms ease-in-out;
	}

	&[data-size="medium"] {
		width: 46px;
		height: 24px;
		border-radius: 64px;
		.point {
			width: 20px;
			height: 20px;
		}
	}

	&[data-active="true"] {
		background: var(--color-success-500);

		.point {
			transform: translateX(110%);
		}
	}
}
