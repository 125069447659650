@import "Configs/Theme/constants.scss";

.root {
	display: inline-block;
	width: 100%;

	> :not(:first-child) {
		margin-top: 24px;
	}
	> :first-child {
		margin-top: 12px;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		> :not(:first-child) {
			margin-left: 16px;
		}
	}
}
