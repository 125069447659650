@import "Configs/Theme/constants.scss";

.root {
	.my-wallet-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: 24px;
	}

	.send-receive-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin-bottom: 24px;

		> :first-child {
			margin-right: 11px;
		}
	}

	.tokens-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.no-tokens-alert {
			width: 100%;
			justify-content: center;
		}
	}

	.paper-airplane {
		transform: rotate(-90deg);
	}
}
