@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;

	@media (max-width: $screen-s) {
		justify-content: unset;
		flex-direction: column;
		align-items: unset;
	}

	.input {
		width: 100%;
		height: 40px;
		border: 1px solid var(--color-neutral-300);
		border-radius: 5px;
	}
}
