@import "Configs/Theme/constants.scss";

.root {
	position: relative;
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: flex-end;

	.searchbar {
		width: 100%;
		transition: width 450ms $custom-easing;
	}

	.search-preview {
		position: absolute;
		width: 100%;
		top: 50px;
		left: 0;
		z-index: 6;
		// background-color is specified in the theme
		box-shadow: var(--shadow-small);
		border-radius: var(--main-searchbar-border-radius);
	}

	.cancel-button-searchbar {
		display: none;
		cursor: pointer;
		margin-left: 16px;

		@media (max-width: $screen-s) {
			display: block;
		}
	}

	svg {
		cursor: pointer;
		width: 14px;
		height: 14px;
		min-width: 14px;
		min-height: 14px;

		path {
			stroke: var(--color-neutral-500);
		}
	}

	.glass-outside {
		opacity: 0;
		width: 0;
	}

	&[data-search-bar-extended="false"] {
		.searchbar {
			width: 0;

			> * {
				padding: 0;
				border: none;
			}

			svg {
				display: none;
			}
		}

		.glass-outside {
			opacity: 1;
			width: initial;
			transition: opacity 1000ms $custom-easing;
		}

		.cancel-button-searchbar {
			display: none;
		}
	}
}
