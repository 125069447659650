@import "Configs/Theme/constants.scss";

.root {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    border-radius: 8px;
    border: 1px solid var(--alert-error-border, rgba(252, 165, 165, 0.15));
    background: var(--alert-error-background, rgba(248, 113, 113, 0.10));

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 24px;
        height: 24px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}
