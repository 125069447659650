@import "Configs/Theme/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-input-field-material);
	border-radius: var(--border-radius-amount-input-field);
	padding: 0 12px;
	border: 1px solid var(--color-neutral-300);
	//color: var(--color-neutral-600);

	&[data-border-right-collapsed="true"] {
		border-radius: 0;
		border-right: 0;
	}

	input {
		border: none;
		background: none;
		width: 40px;
		font-family: var(-font-family-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		font-feature-settings: "salt" on;
		color: var(--color-neutral-500);

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			font-family: var(-font-family-primary);
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			font-feature-settings: "salt" on;
			color: var(--color-neutral-500);
			text-overflow: ellipsis;
		}
	}
}
