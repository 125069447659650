@import "Configs/Theme/constants.scss";

.root {
	margin: 0;
	padding: 0;
	font-family: var(--font-family-primary);
	letter-spacing: -0.02em;
	color: var(--color-neutral-900);

	&.weight-regular {
		font-weight: var(--font-weight-regular);
	}

	&.weight-medium {
		font-weight: var(--font-weight-medium);
	}

	&.weight-semibold {
		font-weight: var(--font-weight-semibold);
	}

	&.weight-bold {
		font-weight: var(--font-weight-bold);
	}

	&.weight-extrabold {
		font-weight: var(--font-weight-extrabold);
	}

	&.color-primary {
		color: var(--color-primary);
	}

	&.color-neutral {
		color: var(--color-neutral-500);
	}

	&.color-secondary {
		color: var(--color-secondary-900);
	}

	&.color-success {
		color: var(--color-success-500);
	}

	&.color-warning {
		color: var(--color-warning-500);
	}

	&.color-error {
		color: var(--color-error-500);
	}
}

.h1 {
	font-size: 40px;
	line-height: 56px;
}

.h2 {
	font-size: 36px;
	line-height: 48px;
}

.h3 {
	font-size: 32px;
	line-height: 48px;
}

.h4 {
	font-size: 28px;
	line-height: 40px;
}

.h5 {
	font-size: 24px;
	line-height: 32px;
}

.h6 {
	font-size: 20px;
	line-height: 32px;
}

.h6Mobile {
	font-size: 18px;
	line-height: 24px;
	letter-spacing: normal;
}

.p,
.span {
	letter-spacing: normal;
	font-family: var(--font-family-secondary);

	&.size-large {
		font-size: 18px;
		line-height: 24px;
	}

	&.size-medium {
		font-size: 16px;
		line-height: 24px;
	}

	&.size-small {
		font-size: 14px;
		line-height: 24px;
	}

	&.size-xsmall {
		font-size: 12px;
		line-height: 16px;
	}
}

.div {
	&.size-large {
		font-size: 52px;
		line-height: 72px;
	}

	&.size-small {
		font-size: 44px;
		line-height: 64px;
	}
}

.overline {
	text-transform: uppercase;
	font-family: var(--font-family-secondary);

	&.size-large {
		font-size: 14px;
		line-height: 20px;
	}

	&.size-small {
		font-size: 12px;
		line-height: 16px;
	}
}
