.input {
	font-family: var(--font-family-secondary);
	font-style: normal;
	font-weight: 400;
	width: 100%;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-neutral-900);
	background-color: var(--background-input-field-material);
	flex: 1;
	border: 1px solid var(--color-neutral-300);
	border-radius: inherit;
	padding: 8px 12px;

	&.hidden {
		display: none !important;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		font-family: var(--font-family-secondary);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		text-overflow: ellipsis;
		color: var(--color-neutral-400);
	}
}

textarea {
	font-family: var(--font-family-secondary);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-neutral-900);
	background-color: var(--background-input-field-material);
	flex: 1;
	border: 0;
	border-radius: var(--border-radius-textarea);
	padding: 8px 12px;

	&.hidden {
		display: none !important;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		font-family: var(--font-family-secondary);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		text-overflow: ellipsis;
		color: var(--color-neutral-400);
	}
}